import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { UniCommonModule } from 'unifonic-spa-common/src';
import { AppWrapperComponent } from './app-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AppWrapperComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UniCommonModule,
    RouterModule,
    TranslateModule
  ],
  exports: [
    AppWrapperComponent
  ]
})
export class AppWrapperModule { }

import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { tap } from 'rxjs/operators';
import { Channels } from "../../../shared/store-mocks/channels.store";
import { Products } from "../../../shared/store-mocks/products.store";
import { MessageLogsLayoutStore } from "../../store/message-logs-layout.store";
import { FiltersFormStore } from "../filter-forms/filter-forms.store";
import { UniAuthFacade } from "unifonic-spa-common/src";

type State = {
}

const initialState: State = {
}

@Injectable()
export class AdvancedFiltersFormStore extends ComponentStore<State>{
    constructor(
        private messageLogsLayoutStore: MessageLogsLayoutStore,
        private filtersFormStore: FiltersFormStore,
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }

    //selectors
    formVisibility$ = this.messageLogsLayoutStore.advancedFiltersFormVisibility$
    formActivity$ = this.select(
        this.filtersFormStore.channels$,
        this.filtersFormStore.products$,
        (channelsValue, productsValue) => {
            return !!channelsValue.length && !!productsValue.length
        }
    )

    campaignIdVisibility$ = this.select(
        this.filtersFormStore.products$,
        (productsValue) =>
            productsValue === Products.CAMPAIGNS
    )

    senderNameVisibility$ = this.select(
        this.filtersFormStore.channels$,
        this.filtersFormStore.products$,
        (channelsValue, productsValue) =>
            (productsValue === Channels.SMS
            || productsValue === Channels.ALL)
            && channelsValue !== ''
            && channelsValue !== Channels.VOICE
            && channelsValue !== Channels.WHATSAPP
    )

    smsStatusVisibility$ = this.select(
        this.filtersFormStore.channels$,
        this.filtersFormStore.products$,
        (channelsValue, productsValue) =>
            channelsValue === Channels.SMS
            && productsValue !== ''
    )

    whatsappStatusVisibility$ = this.select(
        this.filtersFormStore.channels$,
        this.filtersFormStore.products$,
        (channelsValue, productsValue) =>
            channelsValue === Channels.WHATSAPP
            && productsValue !== ''
    )

    voiceStatusVisibility$ = this.select(
        this.filtersFormStore.channels$,
        this.filtersFormStore.products$,
        (channelsValue, productsValue) =>
            channelsValue === Channels.VOICE
            && productsValue !== ''
    )


    senderNumberVisibility$ =  this.select(
        this.filtersFormStore.channels$,
        this.filtersFormStore.products$,
        (channelsValue, productsValue) =>
            channelsValue === Channels.WHATSAPP
            && productsValue !== ''
    )

    customerReasonVisibility$ =  this.select(
      this.filtersFormStore.channels$,
      this.authFacade.featureFlags$,
      (channelsValue, ff) =>
          channelsValue === Channels.SMS && ff['2024-q-2-ci-customer-reason']
    )

    templateVisibility$ = this.select(
        this.filtersFormStore.channels$,
        this.filtersFormStore.products$,
        this.filtersFormStore.campaignId$,
        (channels, products, campaignId) => {
            return channels === Channels.WHATSAPP && products === Products.CAMPAIGNS && campaignId.length
        }
    )

    linkVisibility$ = this.select(
        this.templateVisibility$,
        this.filtersFormStore.templates$,
        (templateVisibility, templates) => templateVisibility && templates
    )

    buttonVisibility$ = this.select(
        this.templateVisibility$,
        this.filtersFormStore.templates$,
        (templateVisibility, templates) => {
            return templateVisibility && templates
        }
    )

    locationVisibility$ = this.select(
        this.linkVisibility$,
        this.buttonVisibility$,
        this.filtersFormStore.links$,
        this.filtersFormStore.buttons$,

        (linkVisibility, buttonVisibility, links, buttons) => {
            return (linkVisibility || buttonVisibility) && (links || buttons)
        }
    )

    callerVisibility$ = this.select(
      this.filtersFormStore.channels$,
      this.authFacade.featureFlags$,
      (channelsValue, ff) =>
          channelsValue === Channels.VOICE && ff['2024-q-3-ci-voice']
    )

    directionVisibility$ = this.select(
      this.filtersFormStore.channels$,
      this.authFacade.featureFlags$,
      (channelsValue, ff) =>
          channelsValue === Channels.VOICE && ff['2024-q-3-ci-voice']
    )

    vm$ = this.select(
        this.filtersFormStore.accountId$,
        this.formActivity$,
        this.formVisibility$,
        this.campaignIdVisibility$,
        this.senderNameVisibility$,
        this.smsStatusVisibility$,
        this.whatsappStatusVisibility$,
        this.senderNumberVisibility$,
        this.customerReasonVisibility$,
        this.templateVisibility$,
        this.linkVisibility$,
        this.buttonVisibility$,
        this.locationVisibility$,
        this.callerVisibility$,
        this.directionVisibility$,
        this.voiceStatusVisibility$,

        this.filtersFormStore.senderName$,
        this.filtersFormStore.senderNumber$,
        this.filtersFormStore.campaignId$,
        this.filtersFormStore.status$,
        this.filtersFormStore.customerReason$,

        this.filtersFormStore.templates$,
        this.filtersFormStore.buttons$,
        this.filtersFormStore.links$,
        this.filtersFormStore.locations$,
        this.filtersFormStore.caller$,
        this.filtersFormStore.direction$,


        (
            accountId,
            formActivity,
            formVisibility,
            campaignIdVisibility,
            senderNameVisibility,
            smsStatusVisibility,
            whatsappStatusVisibility,
            senderNumberVisibility,
            customerReasonVisibility,
            templateVisibility,
            linkVisibility,
            buttonVisibility,
            locationVisibility,
            callerVisibility,
            directionVisibility,
            voiceStatusVisibility,

            senderName, senderNumber, campaignId, status, customerReason, templates, buttons, links, locations, caller, direction
        ) => ({
            accountId,
            formActivity,
            formVisibility,
            campaignIdVisibility,
            senderNameVisibility,
            smsStatusVisibility,
            whatsappStatusVisibility,
            senderNumberVisibility,
            customerReasonVisibility,

            templateVisibility,
            linkVisibility,
            buttonVisibility,
            locationVisibility,
            callerVisibility,
            directionVisibility,
            voiceStatusVisibility,

            senderName, senderNumber, campaignId, status, customerReason,
            templates, buttons, links, locations, caller, direction
        }),
        {debounce: true}
    )


    //effect
    readonly toggleVisibility = this.effect((trigger$) => {
        return trigger$.pipe(
            tap(() => {
                this.messageLogsLayoutStore.toggleAdvancedFiltersFormVisibility()
            })
        )
    })
}

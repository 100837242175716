<ng-container *ngIf="vm$ | async as vm">
    <uni-icon
        name="spinner fa-spin"
        *ngIf="vm.loading"
    ></uni-icon>
    <div
        class="details-content"
        *ngIf="vm.loaded"
    >
    <table class="details-content-properties">
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.callId' | translate}}</strong></td>
            <td>{{vm.entity.id}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.parentCallSid' | translate}}</strong></td>
            <td>{{vm.entity.parentCallSid}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.callSid' | translate}}</strong></td>
            <td>{{vm.entity.callSid}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.callerId' | translate}}</strong></td>
            <td>{{vm.entity.sender}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.secondaryCallerId' | translate}}</strong></td>
            <td>{{vm.entity.secondaryCallerId}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.channel' | translate}}</strong></td>
            <td>{{vm.entity.channel}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.source' | translate}}</strong></td>
            <td>{{vm.entity.callSource}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.messageType' | translate}}</strong></td>
            <td>{{vm.entity.type}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.eventType' | translate}}</strong></td>
            <td>{{vm.entity.eventType}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.common.direction' | translate}}</strong></td>
            <td>{{vm.entity.direction}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.accountName' | translate}}</strong></td>
            <td>{{vm.entity.accountName}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.recipient' | translate}}</strong></td>
            <td>{{vm.entity.recipient}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.country' | translate}}</strong></td>
            <td>{{vm.entity.fromCountry}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.operator' | translate}}</strong></td>
            <td>{{vm.entity.operator}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.callStatus' | translate}}</strong></td>
            <td>{{vm.entity.status}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.statusReason' | translate}}</strong></td>
            <td>{{vm.entity.failureReason}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.duration' | translate}}</strong></td>
            <td>{{vm.entity.duration}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.sentByClient' | translate}}</strong></td>
            <td>{{vm.entity.requestReceivedAt | dateFormat: 'DD-MM-YYYY HH:mm:ss'}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.sentToCarrier' | translate}}</strong></td>
            <td>{{vm.entity.start | dateFormat: 'DD-MM-YYYY HH:mm:ss'}}</td>
        </tr>
        <tr>
            <td><strong>{{'ci.logAnalyzer.details.labels.operatoreReceviedAt' | translate}}</strong></td>
            <td>{{vm.entity.end | dateFormat: 'DD-MM-YYYY HH:mm:ss'}}</td>
        </tr>
    </table>
    </div>
</ng-container>


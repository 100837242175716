import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { UniAuthFacade } from 'unifonic-spa-common/src';

@Component({
  selector: 'app-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.scss']
})
export class AppWrapperComponent implements OnInit {

  constructor(
    private uniAuthFacade: UniAuthFacade,
  ){}


  alternateMenu$ = combineLatest([
    this.uniAuthFacade.account$,
    this.uniAuthFacade.userMe$
  ]).pipe(
    map(([account, user]) => { 
    
      return account?.isWhiteLabel || user?.user?.isAdminLevel
    })
  )

  

  ngOnInit(): void {
  }

}

import { rest } from 'msw'
import { createUser } from './helpers/create-user'

import { getAccountsList } from './api/get-accounts-list'
import { getMessageLogs, getMessageLogsGQL } from './api/get-message-logs'
import { getMessageLogsDetails, getMessageLogsDetailsGQL } from './api/get-message-logs-details'
import { getSenderNameList } from './api/get-sender-name-list'
import { getDashboardDataGQL } from './api/get-dashboard-data'
import { getCampaignGQL } from './api/get-campaign-by-id'
import { getCampaignListGQL } from './api/get-campaign-list'
import { getAggragateTrafficLogs } from './api/get-aggregate-traffic-logs'

import { getCountriesList } from './api/get-countries'
import { generateReport } from './api/post-generate-reports'


export const handlers = [
  generateReport,
  getMessageLogs,
  // getMessageLogsGQL,
  getAccountsList,
  getSenderNameList,
  getMessageLogsDetails,
  // getMessageLogsDetailsGQL,
  // getDashboardDataGQL,
  // getCampaignGQL,
  // getCampaignListGQL,
  // getAggragateTrafficLogs,
  getCountriesList,
  rest.get('http://0.0.0.0:4200/app/api/v2/user/me', (req, res, ctx) => {
    const user = {
      // impersonationSession: {},
      systemSettings: {
        appleTouchIcon: 'favicon.ico',
        domain: 'local',
        emailFrom: 'exampleFrom@email.com',
        emailSupport: 'exampleSupport@email.com',
        favIcon: 'http://0.0.0.0:4200/assets/images/unifonic_favicon.ico',
        logo: 'http://0.0.0.0:4200/assets/images/unifonic_logo.png',
        menuLogo: 'http://0.0.0.0:4200/assets/images/unifonic_logo_white.png',
        name: 'setting name...',
        scheme: 'schemename'
      },
      user: createUser(
        // "096db07c-f82e-11e8-809b-0252151e4412", // for account list match
        "096ec513-f82e-11e8-809b-0252151e4411", //boldare user
        // "0614ab8d-9366-47dd-878d-747f41d9e5f1", //boldare integration user
        // "0614ab8d-9366-47dd-878d-747f41d9e5f1", //boldare 061
        // "5ec72154-bdaa-4782-b017-96110ad4b1f1", // for message logs data
        // '09977118-f82e-11e8-a316-02736070cf67', // for dashboard data

        // 'ae0474f8-edf7-427b-add4-30b0d4edeb31', // for campaigns data
        'Kevin',
        false
      )
    }


    return res(
      ctx.status(200),
      ctx.json(
        user
      )
    )
  })


]

